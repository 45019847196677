import {Component, createRef} from 'react'
import { connect } from 'react-redux'

import Stack from 'react-bootstrap/Stack'
import Button from 'react-bootstrap/Button'
//import Row from 'react-bootstrap/Row'
import {Trash, CartCheck} from 'react-bootstrap-icons'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
//import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'

import OberCall from './obercall'
import ModalEx from './modalex'
//import MenuKaart from './menukaart'
import LocationSelection from './locationselection'

import {CLEAR_BASKET, CREDITS, SET_TRANSFERCREDITS, ADD_NOTIFICATION, SET_LOCATION, CLEAR_PAYMENT, STORE_PAYMENT} from './actions'

import {api_place_order, api_accept_transfer} from './api'
import {format_price} from './util'
import { show_vakjes }  from './config'

class OrderSummary extends Component {
	
	constructor(props) {
		super(props)
		this.placeOrder = this.placeOrder.bind(this)
		this.placePayconiqOrder = this.placePayconiqOrder.bind(this)
		this.askClear = this.askClear.bind(this)
		this.askOrder = this.askOrder.bind(this)
		this.state={comment:"", showClearQuestion:false, showOrderConfirmation:false, showSaldo:true }
		this.mounted=false
		this.oberCallRef = createRef()
		this.refLocSel = createRef()
		this.showQrCode = false
		this.timer = undefined
	}
	
	static defaultProps = {onOrder: (articles, catalog )=>{}, onClear: ( )=>{}, credits:0, orderArticles:{}, articles:[] }
	
	componentWillUnmount(){
		this.mounted=false
		if (this.timer!==undefined){
				clearInterval(this.timer)
				this.timer = undefined
		}
	}
	componentDidMount(){
		this.mounted=true
		
		if (this.props.transfer!==null && this.props.transfer!==undefined) {
			//let messages = this.state.messages
			api_accept_transfer(this.props.identifier, this.props.transfer)
			.then(response => {
				if (this.mounted){
					
					if (response.result){
						this.props.addNotification({title:'Transactie gelukt...', message:"Uw saldo is verhoogd", show:true})
						
					} else {
						this.props.addNotification({title:'Transactie mislukt...', message:response.error.msg, show:true})
					}
					this.props.setTransferCredits(null)
					//this.setState({messages})
				}
				
			})
			.catch(error => {
				console.log(error)
				if (this.mounted){
					//let messages = this.state.messages
					this.props.addNotification({title:'Transactie mislukt...', message:error, show:true})
					//this.setState({messages})
					this.props.setTransferCredits(null)
				}
			})
		}
		this.timer = setInterval(()=>{
			if (this.mounted){
				if (parseFloat(this.props.credits)===0) {
					this.setState({showSaldo:!this.state.showSaldo})
				} else {
					this.setState({showSaldo:true})
				}
			}
		}, 500)
	}
	
	
	
	
	render(){
		let tot_price = 0
		let stuks = 0
		let orderedBarArticles = false;
		let orderedFooiArticles = false;
		for ( let article_id in this.props.orderArticles)
		{
		
			let count = this.props.orderArticles[article_id]
			let id = parseFloat(article_id)
			let article = this.props.articles.find(art => art.article_id === id)
			if (article !== undefined)
			{
				let article_price = parseInt((parseFloat(article.price)*100).toFixed())
				tot_price = tot_price + count*article_price
				
				orderedBarArticles = orderedBarArticles || article.bar_article
				orderedFooiArticles = orderedFooiArticles || !article.bar_article
			}
			stuks = stuks + count
		}
		let vakjes = tot_price/25
		let add_vakjes = show_vakjes?"(= "+vakjes+" vakjes)":"";
		
		tot_price = format_price((tot_price/100).toString())
		
		
		
		let disableButtons = (stuks===0)
		return 	<div style={{color:'yellow'}}>
					<OberCall childRef={ref => (this.oberCallRef = ref)}/>
					<Stack direction="vertical" gap={1} className="p-1"  style={{borderRadius:'5px'}}>
								<Stack direction="horizontal" gap={1} >
									<Button variant="danger rounded-circle" onClick={this.askClear} disabled={disableButtons}><Trash size='1.5em'/></Button>
									<strong style={{width:'100%',textAlign:'center', verticalAlign:'center'}}>
										{ this.state.showSaldo &&	<div onClick={this.oberCallRef.askOber}>Saldo: {format_price(this.props.credits)}€</div>	}
										{ !this.state.showSaldo &&	<Image src="raise.png"  style={{width:30, color:'red'}} onClick={this.oberCallRef.askOber}/>	}
									</strong>
									<strong style={{fontSize:25, verticalAlign:'center'}}>{tot_price}€</strong>
									<Button variant="success rounded-circle" onClick={()=>this.askLocationAndOrder(orderedBarArticles, orderedFooiArticles,"auto")} disabled={disableButtons}><CartCheck size='1.5em'/></Button>
									
								</Stack>
					</Stack>
					<ModalEx show={this.state.modalShow} title={this.state.modalTitle} body={this.state.modalBody} buttons={this.state.modalButtons} no yes onClose={(res, keepopen)=>{if (keepopen!==true){ this.setState({modalShow:false});} this.state.modalResult(res)}}/>
					<Modal show={this.state.showClearQuestion} onHide={()=>this.setState({showClearQuestion:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Bestelling wissen</Modal.Title>
						</Modal.Header>
						<Modal.Body>Wil je alle artikelen uit de bestelling wissen?</Modal.Body>
						<Modal.Footer>
							<Button variant="success" onClick={()=>{this.setState({showClearQuestion:false}); this.props.clearBasket()}}>Bestelling wissen</Button>
						</Modal.Footer>
					</Modal>
					<Modal show={this.state.showOrderConfirmation} onHide={()=>this.setState({showOrderConfirmation:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
						<Modal.Header closeButton>
							<Modal.Title>Bevestig bestelling</Modal.Title>
						</Modal.Header>
						<Modal.Body>Wenst u de bestelling van {stuks} stuks voor {tot_price} € nu te plaatsen? <b>{add_vakjes}</b></Modal.Body>
						<Modal.Footer>
							<Form.Control as="input" type="text" value={this.state.comment} onChange={(e)=>this.setState({comment:e.target.value})} placeholder="Opmerking..."/>
								{/*<Row>*/}
							{this.props.roles.includes("ober") &&
							<Button variant="success" onClick={()=>{this.setState({showOrderConfirmation:false}); this.placeOrder(this.props.orderArticles, this.props.articles,"payconiq")}}>
								 <><Image src="payconiq256.png"  style={{height:40, color:"red", marginRight:5}} />Bestellen voor <div style={{fontWeight:'bold', fontSize:'1.5em'}}>{this.props.location}</div></>
							</Button>}
							<Button variant="success" onClick={()=>{this.setState({showOrderConfirmation:false}); this.placeOrder(this.props.orderArticles, this.props.articles,"auto")}}>
								{this.props.roles.includes("ober") && <><Image src="coin.png"  style={{height:40, color:"red", marginRight:5}} />Bestellen voor <div style={{fontWeight:'bold', fontSize:'1.5em'}}>{this.props.location}</div></>}
								{!this.props.roles.includes("ober") && <>Bestelling plaatsen</>}
							</Button>
							{/*</Row>*/}
						</Modal.Footer>
					</Modal>
					{
						this.props.payment!==undefined && this.props.payment.links!==undefined && this.props.payment_status!==undefined && 
							<Modal show={this.props.payment_status.status!==undefined}  size="lg" aria-labelledby="contained-modal-title-vcenter" centered backdrop="static">
								<Modal.Header>
									{ this.props.payment_status.status==="PENDING" && <Modal.Title>Wachten op betaling...</Modal.Title>}
									{ this.props.payment_status.status==="CANCELLED" && <Modal.Title>Betaling afgebroken</Modal.Title>}
									{ this.props.payment_status.status==="SUCCEEDED" && <Modal.Title>Betaling Gelukt!</Modal.Title>}
									{ this.props.payment_status.status==="EXPIRED" && <Modal.Title>Betaling vervallen</Modal.Title>}
								</Modal.Header>
								<Modal.Body>
									<div style={{backgroundColor:'#ff4785', borderRadius:'20px', padding:'10px'}}>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>Scan and pay with</div>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>Payconiq by Bancontact</div>
									<div className="text-center"><Image className="mx-auto" src={'payconiq_bc.png'} style={{height:100}}/></div>
									<div className="text-center"><Image className="mx-auto" src={this.props.payment.links.qrcode.href} onClick={()=>{window.open(this.props.payment.links.deeplink.href, '_blank');}}/></div>
									<div className="text-center" style={{fontWeight:'bold', fontSize:'1.5em', color:'white'}}>{format_price(this.props.payment_status.amount)} €</div>
									</div>
								</Modal.Body>
								<Modal.Footer>
								{/*<Button variant="success" onClick={()=>this.setState({showQrCode:true})}>QR-code</Button>*/}
									<Button variant="success" onClick={()=>this.props.clearPayment()}>Sluiten</Button>
								</Modal.Footer>
							</Modal>
					}
					<LocationSelection childRef={ref =>(this.refLocSel=ref) } onLocationSelected={loc => {this.props.setLocation(loc);this.askOrder(orderedBarArticles, orderedFooiArticles,"auto") }} />
			
				</div>
	}
	
	askClear() {
		this.setState({showClearQuestion:true})
	}
	askLocationAndOrder(orderedBarArticles, orderedFooiArticles, paymethod) {
		if (!this.props.roles.includes("ober"))
		{
			this.askOrder(orderedBarArticles, orderedFooiArticles, paymethod)
		}
		else
		{
			if (this.props.locations.length > 1 )
			{
				if (this.refLocSel)
				{
					this.props.setLocation(undefined)
					this.refLocSel.SelectLocation()
				}
			}
			else
			{
				this.props.setLocation(this.props.locations[0].name)
				this.askOrder(orderedBarArticles, orderedFooiArticles, paymethod)
			}
		}
	}
	askOrder(orderedBarArticles, orderedFooiArticles, paymethod) {
		if (orderedBarArticles || this.props.roles.includes("ober"))
			this.setState({showOrderConfirmation:true})
		else
			this.placeOrder(this.props.orderArticles, this.props.articles, paymethod)
	}
	androidOS(){
		return  navigator.userAgent.toLowerCase().indexOf('android') > -1;
	}
	createIntentUrl(deepLinkUrl, packageName) {
		// Handle Android Intent URL
		const androidIntentUrl = deepLinkUrl.toLowerCase().replace(/^https?:\/\//, 'intent://') + `#Intent;scheme=https;package=${packageName};end`;

		// Assume we have a way to detect the platform; if not, default to Android URL
		const isAndroid = this.androidOS();
		return isAndroid ? androidIntentUrl : deepLinkUrl; // For iOS, return the direct deep link URL
	}
	placePayconiqOrder(orderedArticles, articles) {
		console.log("pay with payconiq")
		this.props.clearPayment()
		//const newWindow = window.open("about:blank");
		const newWindow = this.androidOS()? undefined : window.open("", '_blank');
		api_place_order(this.props.identifier, orderedArticles, this.props.location, this.state.comment, "payconiq")
		.then(result => {
			console.log("payconiq order result:"+JSON.stringify(result))
			if (result.result)	{
				this.props.storePayment(result["data"])
				if (!this.props.roles.includes("ober"))
				{
					//window.open(result["data"]["links"]["deeplink"]["href"], '_blank');
					//window.open(this.createIntentUrl(result["data"]["links"]["deeplink"]["href"],'mobi.inthepocket.bcmc.bancontact'), '_blank');
					if (newWindow === undefined)
						window.open(this.createIntentUrl(result["data"]["links"]["deeplink"]["href"],'mobi.inthepocket.bcmc.bancontact'), '_blank');
					else {
						newWindow.location.href = this.createIntentUrl(result["data"]["links"]["deeplink"]["href"],'mobi.inthepocket.bcmc.bancontact');
						newWindow.addEventListener('load', function() {
							newWindow.close(); // You can close the window after the app loads
						});
						setTimeout(() => {
						  if (newWindow) {
							newWindow.close(); // Closes the new tabbed window
						  }
						}, 5000);
					}
					//window.location.href = this.createIntentUrl(result["data"]["links"]["deeplink"]["href"],'mobi.inthepocket.bcmc.bancontact');
				}
			} 
			else {
				//console.log(result.error.code)
			}
		})
	}
	placeOrder(orderedArticles, articles, paymethod) {
		//let messages = this.state.messages
		
		if (paymethod==="payconiq")
		{
			return this.placePayconiqOrder(orderedArticles, articles)
		}
		
		let tot_price = 0
		let stuks = 0
		for ( let article_id in orderedArticles)
		{
		
			let count = orderedArticles[article_id]
			let id = parseFloat(article_id)
			let article = articles.find(art => art.article_id === id)
			
			let article_price = parseInt((parseFloat(article.price)*100).toFixed())
			tot_price = tot_price + count*article_price
			stuks = stuks + count
			
		}
		let vakjes = tot_price/25
		let add_vakjes = show_vakjes?" / "+vakjes+" vakjes":"";
		tot_price = (tot_price/100)
		api_place_order(this.props.identifier, orderedArticles, this.props.location, this.state.comment)
		.then(result => {
			if (result.result)
			{
				
				console.log("order result:"+JSON.stringify(result))
				let message = ""
				let title="Drinkgeld..."
				if (result.data.order_has_articles){
					message +="Uw bestelling #"+result.data.order_numbers.join('/')+" ("+stuks+" stuks twv "+format_price(tot_price)+" €"+add_vakjes+") is geplaatst."
					title = 'Bestelling'
				}
				if (result.data.order_has_fooi){
					message +="Bedankt voor de fooi! TTC De Pinte dankt u."
				}
				this.props.addNotification({title, message, show:true})
				
				this.props.clearBasket()
				this.setState({comment:""})
				if (result.data.credits!==undefined) {
					//console.log("received credits:"+(result.data.credits))
					//console.log("updating credits to:"+parseFloat(result.data.credits))
					this.props.updateCredits( parseFloat(result.data.credits))
				} 
				else {
					//console.log("no credits in response?:"+JSON.stringify(result))
				}
			} 
			else {
				console.log(result.error.code)
				if (parseInt(result.error.code)===-2){
					//console.log("loading modalex")
				
					this.setState({
						modalShow:true, 
						modalTitle:"Saldo ontoereikend", 
						modalBody:"Uw saldo is ontoereikend. Betaal met Payconiq of laat een ober komen om uw saldo te verhogen?", 
						modalButtons:[{'variant':'outline-danger', 'caption':<Image src="payconiq256.png"  style={{height:40, color:"red"}} />, 'data':'payconiq'/*, 'keepopen':true*/},{'variant':'success', 'caption':<Image src="raise.png"  style={{height:40, color:"red"}} />, 'data':'yes'}],
						modalResult:(res)=>{
							if (res==="yes"){ 
								console.log("calling ober")
								this.oberCallRef.callWaiter(this.props.identifier, this.props.location,"")
								this.setState({modalShow:false}) 
							
							}
							if (res==="payconiq"){
								this.placePayconiqOrder(orderedArticles, articles)
								/*console.log("pay with payconiq")
								this.props.clearPayment()
								api_place_order(this.props.identifier, orderedArticles, this.props.location, this.state.comment, "payconiq")
								.then(result => {
									console.log("payconiq order result:"+JSON.stringify(result))
									if (result.result)	{
										this.props.storePayment(result["data"])
										window.open(result["data"]["links"]["deeplink"]["href"], '_blank');
									} 
									else {
										//console.log(result.error.code)
									}
								})*/
							}
						}
					})
				} 
				else {
					this.props.addNotification({title:'Bestelling probleem...', message:result.error.msg, show:true})
				}
				//this.setState({messages})
			}
			
		})
		.catch( err =>{
			console.log(err)
			this.props.addNotification({title:'Bestelling probleem...', message:err.toString(), show:true})
			//this.setState({messages})
		})
	}
	
}


function mapDispatchToProps(dispatch) {
	return {
		clearBasket: () => {dispatch({ type: CLEAR_BASKET  })},
		storePayment: (payment) => {dispatch({ type: STORE_PAYMENT, payment  })},
		clearPayment: () => {dispatch({ type: CLEAR_PAYMENT  })},
		updateCredits: (credits) => {dispatch({ type: CREDITS, credits  })},
		setTransferCredits: (transfer) => {dispatch({ type: SET_TRANSFERCREDITS, transfer  })},
		addNotification: (notification) => {dispatch({ type: ADD_NOTIFICATION, notification  })},
		setLocation: (location) => dispatch({type:SET_LOCATION, location})
		
	}
}

function mapStateToProps(state) {
	return {identifier:state.app.identifier, 
	orderArticles: {...state.order.articles}, 
	articles:state.app.articles, 
	location:state.user.location, 
	credits:state.app.credits, 
	transfer:state.app.transfer,
	roles:state.app.roles, 
	payment_status:state.order.payment_status,
	payment:state.order.payment
	,
	locations: state.app.locations
	}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(OrderSummary); 
