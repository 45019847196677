import {Component} from 'react'
import { connect } from 'react-redux'

import ListGroup from 'react-bootstrap/ListGroup'
import Button from 'react-bootstrap/Button'
import CardGroup from 'react-bootstrap/CardGroup'
import Container from 'react-bootstrap/Container'
import Stack from 'react-bootstrap/Stack'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import Badge from 'react-bootstrap/Badge'

import Menu from './menu'
import MenuKaart from './menukaart'
import Order from './order'
import OrderDetails from './orderdetails'
import {api_categories, api_articles, api_prepare_order, api_order_details, api_cancelfinished_order, api_set_article_availability, api_refund_orderline} from './api'
import { format_price} from './util'

class Bar extends Component {
	
	static statusses = ["Besteld","Geannuleerd","In productie","Gepauzeerd","Klaar","Op weg","Geleverd"]
	
	constructor(props)
	{
		super(props)
		this.showOrderDetails = this.showOrderDetails.bind(this)
		this.state={showDetails:false, order:[], windowHeight:0}
		this.handleResize = this.handleResize.bind(this)
		this.mounted = false
		this.setArticleAvailability = this.setArticleAvailability.bind(this)
		this.confirmRefund = this.confirmRefund.bind(this)
	}
	
	format_price(price)
	{
		if (price===undefined || price===null) return ''
		price = price.toString()
		if (price.includes('.')){
			price = price +'00'
			let pos = price.indexOf('.')
			price = price.substring(0,pos+3)
			price = price.replace('.', ',')
		}
		return price
	}
	
	count_as_string(number){
		if (number===0) return ''
		
		return '('+number.toString()+')'
	}
	componentDidMount(){
		this.mounted = true
		api_categories()
		.then( () => api_articles())
		
		window.addEventListener('resize', ()=>{ this.handleResize()})
		this.handleResize()
	}
	
	handleResize(){
		const {  innerHeight: height } = window
		if (this.mounted)
			this.setState({windowHeight:height})
	}
	
	componentWillUnmount(){
		this.mounted = false
		window.removeEventListener('resize', this.handleResize)
	}
	
	render(){
		//console.log("rendering bar")
		//let title_jobs=<h4>Jobs <Badge pill>{(this.props.orders_in.length + this.props.my_orders_preparing.length)}</Badge></h4>
		//let title_completed=<h4>Done <Badge pill>{this.props.orders_ready.length + this.props.orders_out.length}</Badge></h4>
		
		let loc = "Ergens..."
		if (this.state.order && this.state.order.order) loc = this.state.order.order.location
		
		let fooi 
		if ( this.state.order && this.state.order.order)
			fooi = this.state.order.order.fooi_amount
		
		if (fooi>0)
			fooi = <sup><Badge pill bg="danger">{format_price(fooi)}€</Badge></sup>
		else 
			fooi = undefined
		
		
		return < >
					<Stack id="stackall" direction="vertical" gap={1} className="mx-auto px-0 py-0 flex-column" style={{height:'100%'}}>
						<Menu 
							BarJobCount={()=> this.props.orders_in.length + this.props.my_orders_preparing.length}
							BarJobCompleteCount={()=>this.props.orders_ready.length + this.props.orders_out.length}/>
						<Stack className="AppContent py-2" style={{height:this.state.windowHeight-8, overflow:'auto'}}>
						{this.props.menu==='bartasks' && this.render_bartasks()}
						{this.props.menu==='barcompleted' && this.render_barcompleted()}
						{this.props.menu==='barmenu' && this.render_menu()}
						</Stack>
					</Stack>
					{ this.state.order && this.state.order.order && this.state.order.details &&
						<Modal show={this.state.showDetails} onHide={()=>this.setState({showDetails:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
							<Modal.Header closeButton>
								<Modal.Title>#{this.state.order.order.order_number===null?this.state.order.order.order_id:this.state.order.order.order_number} - {loc}{fooi}</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<ListGroup >
									{
										this.props.articles && this.props.articles.length>0 && this.state.order.details && this.state.order.details.map( orderline => 
										{
											let article = this.props.articles.find( art => art.article_id === orderline.article_id)
											if (article && article.bar_article){
												return <ListGroup.Item key={orderline.orderline_id}>
														{orderline.count} x {this.props.articles.find( art => art.article_id === orderline.article_id).name}
														</ListGroup.Item>
											}
											else
												return undefined
											
										})
									}
								</ListGroup>
							</Modal.Body>
							<Modal.Footer>
								{this.state.order.order.comment && this.state.order.order.comment?.length>0 && <div style={{width:'100%'}}>Opm: {this.state.order.order.comment}
								<br/></div>}
								<Stack direction="horizontal" gap={0} style={{width:'100%'}}>
									{this.state.order.order.order_status===4 && <div style={{textAlign:'left'}}><Button variant="danger" onClick={()=>{this.cancelFinalizedOrder(this.state.order.order.order_id);this.setState({showDetails:false}) }}><Image src="retour.png"  style={{height:35}}/></Button></div>}
									<div style={{textAlign:'right', width:'100%'}}>Status: {Order.statusses[this.state.order.order.order_status]}</div>
								</Stack>
							</Modal.Footer>
						</Modal>
					}
					{ this.state.orderline &&
						<Modal show={this.state.askRefund} onHide={()=>this.setState({askRefund:false})} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
							<Modal.Header closeButton>
								<Modal.Title>Bevestig de terugbetaling</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								Kies het aantal van het gekozen artikel dat je wenst uit het order te halen.
							</Modal.Body>
							<Modal.Footer>
								<Stack direction="horizontal" gap={0} style={{width:'100%'}}>
									<div style={{textAlign:'left'}}><Button variant="danger" onClick={()=>{this.setState({askRefund:false}) }}>0</Button></div>
									<div style={{textAlign:'right', width:'100%'}}>
										<Button variant="danger" onClick={()=>{this.setState({askRefund:false}); this.refund(this.state.orderline, 1) }}>1</Button>
											{this.state.orderline.count>1 && <Button variant="danger" className="m-3" onClick={()=>{this.setState({askRefund:false}); this.refund(this.state.orderline, this.state.orderline.count) }}>{this.state.orderline.count}</Button>}
									</div>
								</Stack>
							</Modal.Footer>
						</Modal>
					}
				</>
	}
	
	render_bartasks(){
		//console.log("rendering bartasks")
		
		return 	<>
							<h4 style={{textAlign:'center'}}>Bestellingen in de maak</h4>
							{this.props.my_orders_preparing.length===0 && <h6 style={{textAlign:'center'}}>Geen</h6>}
							{ 
								this.props.my_orders_preparing.length>0 &&
									<Container fluid className="px-0 justify-content-center">
									<CardGroup className="py-0 justify-content-center"  >
									{
										this.props.my_orders_preparing.map( (o) => 	
											<div key={o.order_id} style={{maxWidth:'400px'}}>
												<OrderDetails  key={o.order_id} {...o} refundable={true} Refund={this.confirmRefund} OrderAmount={o.order_amount}/>
											</div>	)
									}
									</CardGroup>	
									</Container>
								
							}
							<h4 style={{textAlign:'center'}}>Nieuwe bestellingen</h4>
							{this.props.orders_in.length===0 && <h6 style={{textAlign:'center'}}>Geen</h6>}
							
							{ 
								this.props.orders_in.length>0 &&
								<Container className="px-0" fluid>
								{
									this.props.orders_in.map( (o) => {
										let fooi = o.fooi_amount
										
										if (fooi>0)
											fooi = <sup><Badge pill bg="danger">{format_price(fooi)}€</Badge></sup>
										else 
											fooi =undefined
		
										let clr="primary"
										if (o.order_status===3) 
											clr="warning"
										return <Button variant={clr} className="mx-1 my-1" key={o.order_id} onClick={()=>this.prepareOrder(o.order_id)}>Maak #{o.order_number===null?o.order_id:o.order_number}{fooi}</Button>
									})
										
								}
								</Container>	
							}
						
						
				
			</>
				
	}
	
	render_barcompleted(){
		
		
		return 	<Container fluid className="p-0 m-0 flex-column" style={{height:'100%'}}>
						<Stack direction="vertical" gap={3} className="mx-auto px-0" >
						<h4 style={{textAlign:'center'}}>Bestellingen klaar voor levering</h4>
						{this.props.orders_ready.length===0 && <h6 style={{textAlign:'center'}}>Geen</h6>}
						{ 
							this.props.orders_ready.length>0 &&
							<div>
							{
								this.props.orders_ready.map( (o) => {
									let fooi = o.fooi_amount
										
									if (fooi>0)
										fooi = <sup><Badge pill bg="danger">{format_price(fooi)}€</Badge></sup>
									else 
										fooi =undefined
									return <Button className="mx-1 my-1" key={o.order_id} onClick={()=>this.showOrderDetails(o.order_id)}>#{o.order_number===null?o.order_id:o.order_number}{fooi}</Button>
								})
									
							}
							<br/>
							</div>	
							
						}
						<h4 style={{textAlign:'center'}}>Uitgeleverde bestellingen</h4>
						{this.props.orders_out.length===0 && <h6 style={{textAlign:'center'}}>Geen</h6>}
						{ 
							this.props.orders_out.length>0 &&
							<div>
							{
								this.props.orders_out.map( (o) => {
									let fooi = o.fooi_amount
										
									if (fooi>0)
										fooi = <sup><Badge pill bg="danger">{format_price(fooi)}€</Badge></sup>
									else 
										fooi =undefined
									return <Button className="mx-1 my-1" key={o.order_id} onClick={()=>this.showOrderDetails(o.order_id)}>#{o.order_number===null?o.order_id:o.order_number}{fooi}</Button>
								})
									
							}
							</div>	
						}
						</Stack>
						
				
			</Container>
				
	}
	render_menu(){
		
		
		return 	<MenuKaart className="flex-grow-1" type="bar" onStockChange={this.setArticleAvailability}/>
					
				
	}
	
	setArticleAvailability(article_id, available) {
		console.log("stock change...")
		api_set_article_availability(this.props.identifier, article_id, available)
		.catch (err => console.log(err))
	}
	
	
	prepareOrder(order_id) {
		api_prepare_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	showOrderDetails(order_id) {
		api_order_details(order_id)
		.then( order => this.setState({order, showDetails:true}))
		.catch(err => console.log(err))
	}
	
	cancelFinalizedOrder(order_id) {
		api_cancelfinished_order(this.props.identifier, order_id)
		.catch (err => console.log(err))
	}
	
	confirmRefund(orderline, refundedCB)
	{
		this.setState({orderline, askRefund:true, refundedCB})
	}
	
	refund(orderline, numRefund)
	{
		console.log("refunding:"+ numRefund+" on orderline "+JSON.stringify(orderline))
		
		if (numRefund > 0){
			api_refund_orderline(this.props.identifier, orderline.orderline_id, numRefund)
			.then(result => this.state.refundedCB())
			.catch (err => console.log(err))
			
			
		}
	}

}


function mapDispatchToProps(dispatch) {
	return {
		/*clearBasket: () => {dispatch({ type: CLEAR_BASKET  })}*/
		
	}
}

function mapStateToProps(state) {
	//console.log("new bar STATE:"+JSON.stringify(state.app.barorders))
	return {
		identifier:state.app.identifier,
		orders_in:state.app.barorders.filter(order => order.order_status===0 ||((order.order_status===3) && (order.bar_identifier===state.app.identifier))).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		my_orders_preparing:state.app.barorders.filter(order => (order.order_status===2) && order.bar_identifier===state.app.identifier).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders_preparing:state.app.barorders.filter(order => order.order_status===2 || order.order_status===3).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders_ready:state.app.barorders.filter(order => order.order_status===4).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		orders_out:state.app.barorders.filter(order => order.order_status===5 || order.order_status===6).sort((a,b)=>parseInt(a.order_id)-parseInt(b.orderId)),
		categories:state.app.categories,
		articles:state.app.articles,
		menu:state.app.menu
		
	}
	
}
  
export default connect(mapStateToProps,mapDispatchToProps)(Bar); 